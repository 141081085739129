<template>
  <div>
    <AppCard
      class="autoresponders-page__panel"
      body-class="flex column q-pa-lg"
      header-color="bg-grey-11"
      :title="$t('label.autoresponders')"
      bordered
    >
      <AutoresponderList
        v-model="autoresponders"
        class="autoresponders-page__panel-body"
        @update="onUpdate"
      />
    </AppCard>
    <div class="page-hint">
      {{ $t('text.autorespondersFormPageHint') }}
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import randomString from 'common/helpers/randomString';
import AutoresponderList from 'common/pages/dashboard/components/autoresponders/AutoresponderList';
import { builderMixin } from 'src/pages/dashboard/form/mixins';

export default {
  name: 'AutorespondersPage',
  stepName: ENUMS.STEPS.AUTORESPONDERS,
  components: {
    AppCard,
    AutoresponderList,
  },
  mixins: [builderMixin],
  data() {
    return {
      autoresponders: [],
    };
  },
  async created() {
    this.autoresponders = [...this.formPart?.autoresponders].map((item) => ({
      id: randomString(),
      ...item,
    }));
  },
  methods: {
    onUpdate(autoresponders) {
      this.formPart.autoresponders = autoresponders;
    },
  },
};
</script>
